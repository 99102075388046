import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { ModalDoctorCheckinComponent } from "../modals/modal-doctor-checkin/modal-doctor-checkin.component";
import { ModalDoctorDetailComponent } from "../modals/modal-doctor-detail/modal-doctor-detail.component";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { DoctorService } from "../service/doctor.service";
import { InformationsService } from "../service/informations.service";
import { ModalDoctorVinculateComponent } from "../modals/modal-doctor-vinculate/modal-doctor-vinculate.component";
import { MatSort } from "@angular/material/sort";
import { ModalDoctorNotificationComponent } from "../modals/modal-doctor-notification/modal-doctor-notification.component";

@Component({
  selector: "app-doctors",
  templateUrl: "./doctors.component.html",
  styleUrls: ["./doctors.component.css"],
})
export class DoctorsComponent implements OnInit {
  constructor(
    private doctorService: DoctorService,
    private modalService: MDBModalService,
    private informationsService: InformationsService
  ) {}
  company_type = localStorage.getItem('companyType')
  displayedColumns: string[] = [
    "name",
    // "email",
    "cpf",
    "crm",
    "rqe",
    "specialty",
    "gender",
    // "active",
    "icons",
  ];
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 100;

  associations = [];
  specialties = [];
  is_company_admin: boolean = false;
  filterText = ""
  allData = []
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  
  ngOnInit(): void {
    if (
      (localStorage.getItem("admin") === "true") &&
      localStorage.getItem("company") !== null
    ) {
      this.is_company_admin = true;
    }

    this.getDoctors(1);

    this.informationsService.getAssociation().subscribe((data) => {
      this.associations = data;
    });

    this.informationsService.getSpecialty().subscribe((data) => {
      this.specialties = data;
    });
  }

  next() {
    if (this.page_count2 < this.count_total && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getDoctors(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getDoctors(this.page - 1);
    }
  }

  getDoctors(page) {
    this.is_loaded = false;
    this.doctorService.getDoctors().subscribe(
      (data) => {
        //console.log(data);
        this.allData = data;
        this.data_source.data = data;
        this.data_source.sort = this.sort;
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.is_loaded = true;
      },
      (error) => {
        //console.log(error);
        this.is_loaded = true;
      }
    );
  }

  changeStatus(active, id) {
    this.doctorService.editDoctorActive(active, id).subscribe(
      (data) => {},
      (error) => {
        this.error(error, "editar");
      }
    );
  }

  newDoctor() {
    this.modalRef = this.modalService.show(ModalDoctorDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Profissional",
        btn_text: "Adicionar",
        is_new: true,
        associations: this.associations,
        specialties: this.specialties,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      console.log("aqui");
      if (result == "update") {
        this.getDoctors(this.page);
      }
      this.modalRef.hide();
    });
  }

  addDoctor() {
    this.modalRef = this.modalService.show(ModalDoctorVinculateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: "Vincular Profissional",
        btn_text: "Adicionar",
        is_new: true,
        associations: this.associations,
        specialties: this.specialties,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      console.log("aqui");
      if (result == "update") {
        this.getDoctors(this.page);
      }
      this.modalRef.hide();
    });
  }

  editDoctor(doctor) {
    this.modalRef = this.modalService.show(ModalDoctorDetailComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar Profissional",
        btn_text: "Salvar",
        is_new: false,
        doctor_clone: doctor,
        associations: this.associations,
        specialties: this.specialties,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getDoctors(this.page);
      }
    });
  }

  checkinDoctor(doctor) {
    this.modalRef = this.modalService.show(ModalDoctorCheckinComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl",
      containerClass: "modal-checkin",
      animated: false,
      data: {
        title: "Check-in e Check-out",
        doctor_id: doctor.id,
        doctor_name: doctor.name,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getDoctors(this.page);
      }
    });
  }

  deleteDoctor(doctor) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Tem certeza...",
        text: `de que deseja deletar o médico ${doctor.name}?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.openMessage("Por favor, aguarde", "", true);
        this.doctorService.deleteDoctor(doctor.id).subscribe(
          (data) => {
            this.modalRef.hide();
            this.openMessage(
              "Deletado!",
              "Profissional deletado com sucesso.",
              false
            );
            this.getDoctors(this.page);
          },
          (error) => {
            this.modalRef.hide();
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  notification(doctor) {
    this.modalRef = this.modalService.show(ModalDoctorNotificationComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "modal-checkin",
      animated: false,
      data: {
        title: "Notificações dos pacientes",
        doctor_id: doctor.id,
        doctor_name: doctor.name,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getDoctors(this.page);
      }
    });
  }

  makeFilter(){
    let data = []
    if (!this.filterText || !this.filterText.trim().length) {
      data = this.allData
    } else {
      data = this.allData.filter((item) => {
        return item.name.toLowerCase().indexOf(this.filterText.toLowerCase()) > -1
      })
    }

    this.page = 1;
    this.count_total = this.allData.length;
    this.page_count1 = 1  * this.page_size;
    if (this.page_count1 + (this.page_size - 1) > this.count_total) {
      this.page_count2 = this.count_total;
    } else {
      this.page_count2 = this.page_count1 + (this.page_size - 1);
    }
    // console.log(data);
    data.sort((a, b) => a.name.localeCompare(b.name))
    this.data_source.data = data;
    this.data_source.sort = this.sort;
    
  }
}
