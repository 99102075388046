import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormHomeService } from 'src/app/service/form-home.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})

export class ContactUsComponent implements OnInit {

  constructor(
    private formHomeService: FormHomeService,
    private _snackBar: MatSnackBar
  ) { }

  loading: boolean = false;
  warningMap = new Map();

  name: string = '';
  email: string = '';
  phone: string = '';
  message: string = '';

  email_2: string = '';

  error_message: string = '';
  error_message_email: string = '';

  ngOnInit(): void {

  }

  sendInfoForm() {
    this.formHomeService.sendForm('contact', this.name, this.email, this.phone, null, null, null, null, null, this.message).subscribe(data => {
      //console.log(data)

      this.name = '';
      this.email = '';
      this.phone = ''; 
      this.message = '';

      this.loading = false;
      this.openSnackBar("Enviado!", "Fechar")
    },
      error => {
        this.loading = false;
        //console.log(error)
      })
  }

  sendForm() {
    this.error_message = ''
    this.warningMap = new Map();

    if (this.name == "" || this.name == null) {
      this.error_message = 'Por favor, insira o nome.'
    }
    else if (this.email == "" || this.email == null) {
      this.error_message = 'Por favor, insira o email.'
    }
    else if (this.phone == "" || this.phone == null) {
      this.error_message = 'Por favor, insira o telefone.'
    }
    else if (this.message == "" || this.message == null) {
      this.error_message = 'Por favor, insira a mensagem.'
    }

    if (this.error_message == '' && !this.loading) {
      this.loading = true;
      this.sendInfoForm()
    }
  }


  sendEmail() {
    this.error_message_email = ''
    this.warningMap = new Map();

    if (this.email_2 == "" || this.email_2 == null) {
      this.error_message_email = 'Por favor, insira o email.'
    }

    if (this.error_message_email == '' && !this.loading) {
      this.loading = true;
      // this.sendInfoForm()
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

}
