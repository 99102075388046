import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppConfig } from "../app.config";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FormHomeService {

  constructor(private http: HttpClient) { }

  sendForm(type, name, email, phone, crm, rqe, especialidade, cnpj, atividade, message): Observable<any> {
    let body = new HttpParams()
      .set('type_faq', type)
      .set('name', name)
      .set('email', email)

    if (phone != null)
      body = body.append('tel', phone)

    if (crm != null)
      body = body.append('crm', crm)

    if (rqe != null)
      body = body.append('rqe', rqe)

    if (especialidade != null)
      body = body.append('specialty', especialidade)

    if (cnpj != null)
      body = body.append('cnpj', cnpj)

    if (atividade != null)
      body = body.append('atividade', atividade)

    if (message != null)
      body = body.append('message', message)

    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      // .set('Authorization', `token ${localStorage.getItem('token')}`)

    return this.http.post<any>(AppConfig.path + "core/faq/", body.toString(), { headers })
  }
}
