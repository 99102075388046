import { DatePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { UserService } from "src/app/service/user.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { DoctorService } from "src/app/service/doctor.service";

@Component({
  selector: "modal-patient-detail",
  templateUrl: "./modal-patient-detail.component.html",
  styleUrls: ["./modal-patient-detail.component.css"],
})
export class ModalPatientDetailComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private doctorService: DoctorService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  patient_clone;
  company = [];
  home_care_selectd = null;
  notUseCPF = false;
  patient = {
    cpf: null,
    password: null,
    name: null,
    email: null,
    birthday: null,
    gender: null,
    company: null,
    phone: null,
    number: null,
    cep: null,
    city: null,
    complement: null,
    neighborhood: null,
    street: null,
    state: null,
    config_heartbeat_minutes: 1,
    config_pressure_minutes: 1,
    config_temperature_minutes: 1,
    config_oxygenation_minutes: 1,
    config_glucose_minutes: 1,

    config_heartbeat_minimo: null,
    config_heartbeat_maximo: null,
    config_temperature_minimo: null,
    config_temperature_maximo: null,
    config_oxygenation_minimo: null,
    config_oxygenation_maximo: null,
    config_glucose_minimo: null,
    config_glucose_maximo: null,
    config_pressure_sistolica_minimo: null,
    config_pressure_sistolica_maximo: null,
    config_pressure_diastolica_minimo: null,
    config_pressure_diastolica_maximo: null,
    config_respiratoryFrequency_minimo: null,
    config_respiratoryFrequency_maximo: null,
    user: null,
    receive_welcome_email: false,
    created_by_doctor: false,
  };

  confirm_password: string;

  validator = new ValidatorUtils();
  doctors = [];
  selected_doctors = [];
  use_password = false;
  initial_cpf = "";
  ngOnInit() {
    if (this.patient_clone != null && !this.is_new) {
      for (var key in this.patient) {
        if (key == "cpf") this.patient[key] = this.patient_clone.user[key];
        else if (key == "birthday") {
          this.patient[key] = `${this.patient_clone[key].substring(
            8,
            10
          )}/${this.patient_clone[key].substring(5, 7)}/${this.patient_clone[
            key
          ].substring(0, 4)}`;
        } else if (key != "password" && key !== "company")
          this.patient[key] = this.patient_clone[key];
        else if (key === "company")
          this.patient[key] = this.patient_clone[key].id;
      }
      this.initial_cpf = this.patient.cpf;
    }

    this.companyService.getCompanys().subscribe((data) => {
      const company = parseInt(localStorage.getItem("company"));

      this.company = data.filter((item) => item.id === company);
      this.patient.company = data
        .filter((item) => item.id === company)
        .map((x) => x.id)
        .reduce((old, value) => value, 0);
    });

    this.getDoctors();
    this.getDoctorsPatient();
  }

  getDoctorsPatient() {
    if (this.is_new) return;
    this.userService.getPatientDoctors(this.patient_clone.id).subscribe(
      (data) => {
        this.selected_doctors = data.map((item) => item.doctor);
      },
      (error) => {}
    );
  }

  getDoctors() {
    this.doctorService.getDoctors().subscribe(
      (data) => {
        this.doctors = data;
        if (this.is_new) this.selected_doctors = data.map((item) => item.id);
      },
      (error) => {}
    );
  }

  checkPatient() {
    if (this.validator.isEmptyOrNull(this.patient.name)) {
      this.openMessage("Por favor,", "insira o nome do paciente.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.patient.birthday)) {
      this.openMessage(
        "Por favor,",
        "insira a data de nascimento do paciente.",
        false
      );
      return false;
    } else if (
      this.patient.receive_welcome_email &&
      this.validator.isEmptyOrNull(this.patient.email)
    ) {
      this.openMessage(
        "Por favor,",
        "Para receber o e-mail de cadastro é necessário informar o e-mail.",
        false
      );
      return false;
    } else if (
      !this.notUseCPF &&
      this.validator.isEmptyOrNull(this.patient.cpf)
    ) {
      this.openMessage("Por favor,", "insira o CPF do paciente.", false);
      return false;
    }
    // else if(this.validator.isEmptyOrNull(this.patient.gender)){
    //     this.openMessage("Por favor,", "selecione o gênero do paciente.", false);
    //     return false
    // }
    else if (
      this.use_password &&
      this.validator.isEmptyOrNull(this.patient.password)
    ) {
      this.openMessage("Por favor,", "insira a senha do paciente.", false);
      return false;
    } else if (
      this.use_password &&
      this.validator.isEmptyOrNull(this.confirm_password)
    ) {
      this.openMessage("Por favor,", "confirme a senha do paciente.", false);
      return false;
    } else if (
      this.use_password &&
      this.patient.password != this.confirm_password
    ) {
      this.openMessage(
        "Por favor,",
        "informe uma senha igual a confirmação de senha.",
        false
      );
      return false;
    }
  
    else {
      var day = this.patient.birthday.substring(0, 2);
      var month = this.patient.birthday.substring(3, 5);
      var year = this.patient.birthday.substring(6, 10);

      var date = new Date(year, month - 1, day);
      var time = new Date().getTime() - date.getTime();
      var time_passed = time / 1000 / 60;

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else if (time_passed < 0) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else {
        this.patient.config_glucose_minutes = 1;
        this.patient.config_pressure_minutes = 1;
        this.patient.config_heartbeat_minutes = 1;
        this.patient.config_oxygenation_minutes = 1;
        this.patient.config_temperature_minutes = 1;
        if (this.is_new) this.createPatient(`${year}-${month}-${day}`);
        else this.editPatient(`${year}-${month}-${day}`);
      }
    }
  }

  getPatientForm() {
    let body = { ...this.patient };
    if (body.gender == null) {
      delete body.gender;
    }

    if (body.email === null || !body.email.length) {
      delete body.email;
    }

    if (body.config_heartbeat_minimo == null) {
      delete body.config_heartbeat_minimo;
    }
    if (body.config_heartbeat_maximo == null) {
      delete body.config_heartbeat_maximo;
    }
    if (body.config_temperature_minimo == null) {
      delete body.config_temperature_minimo;
    }
    if (body.config_temperature_maximo == null) {
      delete body.config_temperature_maximo;
    }
    if (body.config_oxygenation_minimo == null) {
      delete body.config_oxygenation_minimo;
    }
    if (body.config_oxygenation_maximo == null) {
      delete body.config_oxygenation_maximo;
    }
    if (body.config_glucose_minimo == null) {
      delete body.config_glucose_minimo;
    }
    if (body.config_glucose_maximo == null) {
      delete body.config_glucose_maximo;
    }
    if (body.config_pressure_sistolica_minimo == null) {
      delete body.config_pressure_sistolica_minimo;
    }
    if (body.config_pressure_sistolica_maximo == null) {
      delete body.config_pressure_sistolica_maximo;
    }
    if (body.config_pressure_diastolica_minimo == null) {
      delete body.config_pressure_diastolica_minimo;
    }
    if (body.config_pressure_diastolica_maximo == null) {
      delete body.config_pressure_diastolica_maximo;
    }
    if (body.config_respiratoryFrequency_maximo == null) {
      delete body.config_respiratoryFrequency_maximo;
    }
    if (body.config_respiratoryFrequency_minimo == null) {
      delete body.config_respiratoryFrequency_minimo;
    }

    if (body.phone == null) {
      delete body.phone;
    }
    if (body.number == null) {
      delete body.number;
    }
    if (body.cep == null) {
      delete body.cep;
    }
    if (body.city == null) {
      delete body.city;
    }
    if (body.complement == null) {
      delete body.complement;
    }
    if (body.neighborhood == null) {
      delete body.neighborhood;
    }
    if (body.street == null) {
      delete body.street;
    }
    if (body.state == null) {
      delete body.state;
    }

    if (this.is_new && this.notUseCPF) {
      delete body.cpf;
    }

    return body;
  }

  createPatient(birthday) {
    this.openMessage("Por favor, aguarde", "", true);

    if (!this.use_password) {
      this.patient.password = new Date().toString();
    }

    var passwordMD5 = Md5.init(this.patient.password).toUpperCase();
    var body: any = this.getPatientForm();
    if (this.selected_doctors.length > 0) {
      body.doctors = this.selected_doctors;
    }
    this.userService.createPatient(body, passwordMD5, birthday).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage(
          "Criado com sucesso!",
          "Paciente adicionado com sucesso",
          // "Faça a validação do usuário que foi enviada por e-mail.",
          false
        );
        this.action.next("update");
        this.modalRef.hide();
      },
      (error) => {
        //console.log(error.error);
        if (error && error.error) {
          if (error.error.email) {
            this.modalRef2.hide();
            const msg = error.error.email[0].replace("patient", "Paciente");
            this.errorBack(msg, "criar");
          } else if (error.error.company) {
            this.modalRef2.hide();
            this.errorBack(
              "Informe a empresa que o paciente está vinculado",
              "criar"
            );
          } else if (error.error.cpf) {
            const msg = error.error.cpf[0].replace("user", "Usuário");
            this.modalRef2.hide();
            this.errorBack(msg, "criar");
          } else {
            this.error(error, "criar");
          }
        } else {
          this.error(error, "criar");
        }

        this.modalRef2.hide();
      }
    );
  }

  editPatient(birthday) {
    this.openMessage("Por favor, aguarde", "", true);
    var body: any = this.getPatientForm();
    if (this.selected_doctors.length > 0) {
      body.doctors = this.selected_doctors;
    }
    this.userService
      .editPatient(this.patient_clone.id, body, birthday)
      .subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage("Salvo!", "Paciente editado com sucesso.", false);
          this.action.next("update");
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
  }

  errorBack(error, string) {
    var msg = error;

    this.openMessage(
      `Erro, não foi possível ${string} o paciente.`,
      msg,
      false
    );
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else if (error.error && error.error.detail) msg = error.error.detail;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(
      `Erro, não foi possível ${string} o paciente.`,
      msg,
      false
    );
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "modal-lg",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  validCpf(strCPF) {
    strCPF = strCPF
      .replace(".", "")
      .replace(".", "")
      .replace(".", "")
      .replace("-", "");
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF == "00000000000") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto == 10 || Resto == 11) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) return false;
    return true;
  }

  recovery() {
    this.openMessage("Por favor, aguarde", "", true);
    this.userService.recoveryPassword({ cpf: this.initial_cpf }).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage(
          "Salvo!",
          "As instruções de recuperação de senha foram enviadas para o e-mail de cadastro.",
          false
        );
        this.action.next("update");
        this.modalRef.hide();
      },
      (err) => {
        this.modalRef2.hide();
        this.error(err, "editar");
      }
    );
  }
}
