import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { ModalFormAnswerListComponent } from "../modals/modal-form-answer-list/modal-form-answer-list.component";
import { ModalFormAnswerComponent } from "../modals/modal-form-answer/modal-form-answer.component";
import { ModalFormCreateComponent } from "../modals/modal-form-create/modal-form-create.component";
import { ModalFormDetailComponent } from "../modals/modal-form-detail/modal-form-detail.component";
import { ModalFormFillComponent } from "../modals/modal-form-fill/modal-form-fill.component";
import { ModalFormPermissionsComponent } from "../modals/modal-form-permissions/modal-form-permissions.component";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import { ModalFormModelListComponent } from "../modals/modal-form-model-list/modal-form-model-list.component";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.css"],
})
export class FormsComponent implements OnInit {
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private route: ActivatedRoute,

    private router: Router
  ) {}
  company_type = localStorage.getItem("companyType");
  displayedColumns: string[] = [
    "empty",
    "sort",
    "id",
    "title",
    "date",
    "activated",
    "icons",
  ];
  data_source = new MatTableDataSource<any>();

  modalRef: MDBModalRef;

  is_loaded: boolean = false;

  /* pagination related variabels */
  page: number = 1;
  count_total: number = 0;
  page_count1: number = 0;
  page_count2: number = 0;
  page_size = 10;

  is_company_admin: boolean = false;
  editingForm = false;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  core_plan_id: any;
  core_plan_name: any;

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.core_plan_id = params["id"];
      this.core_plan_name = params["name"];
    });
    if (
      localStorage.getItem("admin") === "true" &&
      localStorage.getItem("company") !== null
    ) {
      this.is_company_admin = true;
      
    }
    this.getForms(1);
  }

  truncateString(str, max) {
    return str.length > max ? str.substr(0, max - 1) + "…" : str;
  }

  getForms(page) {
    this.is_loaded = false;
    
    this.companyService.getForms(this.core_plan_id).subscribe(
      (data) => {
        //console.log(data);
        const auxData = data.map((item) => {
          item.date = `${item.created_at.substring(
            8,
            10
          )}/${item.created_at.substring(5, 7)}/${item.created_at.substring(
            0,
            4
          )}`;
          return item;
        });

        auxData.sort((a, b) => a.position < b.position);
        this.data_source.data = auxData;
        this.data_source.sort = this.sort;
        // pagination calc
        this.page = page;
        this.count_total = data.length;
        this.page_count1 = 1 + (page - 1) * this.page_size;
        if (this.page_count1 + (this.page_size - 1) > this.count_total) {
          this.page_count2 = this.count_total;
        } else {
          this.page_count2 = this.page_count1 + (this.page_size - 1);
        }
        this.is_loaded = true;
      },
      (error) => {
        //console.log(error);
        this.is_loaded = true;
      }
    );
  }

  newForm() {
    this.modalRef = this.modalService.show(ModalFormCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Formulário",
        btn_text: "Adicionar",
        is_new: true,
        core_plan_id: this.core_plan_id ?? null,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getForms(this.page);
        this.modalRef.hide();
      }
    });
  }

  toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  editForm(form) {
    this.modalRef = this.modalService.show(ModalFormPermissionsComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Editar permissões e legenda",
        btn_text: "Salvar",
        selectedDoctors: form.doctors,
        access_to_patient: form.access_to_patient,
        captionTitle: form.caption_text,
        fileImage: form.caption_image,
        questions: form.questions,
      },
    });
    this.modalRef.content.action.subscribe(async (result: any) => {
      const data = {
        doctors: result.permissions,
        access_to_patient: result.access_to_patient,
        captionTitle: result.captionTitle,
      };
      console.log(result);
      if (result.file) {
        data["captionImage"] =
          result.file === "issame"
            ? "issame"
            : await this.toBase64(result.file);
      }

      const questions = [];
      for await (const question of result.questions) {
        console.log(question, question.file === "issame");
        const q = {
          captionTitle: question.captionTitle,
          id: question.id,
        };

        if (question.file) {
          q["captionImage"] =
            question.file === "issame"
              ? "issame"
              : await this.toBase64(question.file);
        }
        questions.push(q);
      }
      data["questions"] = questions;

      this.companyService.updateFormDoctors(data, form.id).subscribe(
        () => {
          this.openMessage("Sucesso!", "Permissões atualizadas", false);
          this.getForms(this.page);
          this.modalRef.hide();
        },
        (err) => {
          this.openMessage(
            "Erro",
            "Ocorreu um erro ao salvar os profissionais",
            false
          );
        }
      );
    });
  }

  fillForm(form) {
    //console.log(form);
    this.modalRef = this.modalService.show(ModalFormFillComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      width: "100%",
      ignoreBackdropClick: true,
      containerClass: "",
      class: "modal-dialog-centered modal-dialog modal-xl",
      StyleSheet: { width: "100% !important" },
      animated: false,
      data: {
        title: "Preencher Formulário",
        btn_text: "Salvar",
        is_new: false,
        form_clone: form,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "update") {
        this.getForms(this.page);
      }
    });
  }

  deleteForm(form) {
    // this.openMessage(
    //   `Informação.`,
    //   "Essa funcionalidade está sendo desenvolvida, em breve será liberada para você =)",
    //   false
    // );
    // return;
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar o formulário ${form.title}?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.openMessage("Por favor, aguarde", "", true);
        this.companyService.deleteForm(form.id).subscribe(
          (data) => {
            this.modalRef.hide();
            this.openMessage(
              "Deletado!",
              "Formulário deletado com sucesso.",
              false
            );
            this.getForms(this.page);
          },
          (error) => {
            this.modalRef.hide();
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  answerForm(form) {
    this.modalRef = this.modalService.show(ModalFormAnswerListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-xl",
      containerClass: "",
      animated: false,
      data: {
        title: "Respostas do Formulário",
        form_id: form.id,
        form_title: form.title,
        form_questions: form.questions,
        form: form,
      },
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  next() {
    if (this.page_count2 < this.count_total && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getForms(this.page + 1);
    }
  }

  prev() {
    if (this.page > 1 && this.is_loaded) {
      this.data_source.data = [];
      this.is_loaded = false;
      this.getForms(this.page - 1);
    }
  }

  editFormOrder() {
    if (this.editingForm) {
      this.saveNewOrder();
    } else {
      this.editingForm = true;
    }
  }

  saveNewOrder() {
    const savePositions = [];
    let id = 0;
    for (const x of this.data_source.data) {
      savePositions.push({ id: x.id, position: id++ });
    }

    this.companyService.updateFormPosition({ data: savePositions }).subscribe(
      (data) => {
        this.openMessage(
          "Sucesso",
          `Formulários atualizados com sucesso.`,
          false
        );
        this.editingForm = false;
      },
      (err) => {
        this.openMessage("Erro", `Ocorreu um erro, tente novamente.`, false);
      }
    );
  }

  goUp(index) {
    const data = this.data_source.data;
    const a = data[index];
    data[index] = data[index - 1];
    data[index - 1] = a;
    this.data_source.data = data;
  }

  goDown(index) {
    const data = this.data_source.data;
    const a = data[index];
    data[index] = data[index + 1];
    data[index + 1] = a;
    this.data_source.data = data;
  }

  showFormModels() {
    this.modalRef = this.modalService.show(ModalFormModelListComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Formulários modelo",
        btn_text: "Adicionar",
        is_new: true,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.showCreateWithData(result);
        this.modalRef.hide();
      }
    });
  }

  showCreateWithData(form) {
    console.log(form);

    form.questions.map((question) => {
      const ans = {
        titleQuestion: question.title,
        position: question.position,
        is_selectable: question.is_selectable,
        is_list: question.is_list,
        is_numeric: question.is_numeric,
        is_numeric_scale: question.is_numeric_scale,
        is_image: question.is_image,

        is_date: question.is_date,
        type_date: question.type_date,
        is_frequency: question.is_frequency,
        min_frequency: question.min_frequency,
        max_frequency: question.max_frequency,
        range_frequency: question.range_frequency,
        is_scale: question.is_scale,

        required: question.required,
        number_of_choices: question.number_of_choices,
        alternatives: [],
      };

      ans.alternatives = question.alternatives.map((alternative) => {
        const aux = {
          titleQuestion: alternative.title,
          position: alternative.position,
          score: alternative.score,
        };
        return aux;
      });

      return ans;
    });
    this.modalRef = this.modalService.show(ModalFormCreateComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg",
      containerClass: "",
      animated: false,
      data: {
        title: "Adicionar Formulário",
        btn_text: "Adicionar",
        is_new: true,
        core_plan_id: this.core_plan_id,
        titleQuestion: form.title,
        caption_image: form.caption_image,
        caption_text: form.caption_text,
        questions: form.questions.map((question) => {
          const ans = {
            title: question.title,
            position: question.position,
            is_selectable: question.is_selectable,
            is_list: question.is_list,
            is_numeric: question.is_numeric,
            is_numeric_scale: question.is_numeric_scale,
            is_image: question.is_image,
            is_date: question.is_date,
            type_date: question.type_date,
            is_frequency: question.is_frequency,
            min_frequency: question.min_frequency,
            max_frequency: question.max_frequency,
            range_frequency: question.range_frequency,
            is_scale: question.is_scale,
            required: question.required,
            number_of_choices: question.number_of_choices,
            alternatives: [],
          };

          ans.alternatives = question.alternatives.map((alternative) => {
            const aux = {
              title: alternative.title,
              position: alternative.position,
              score: alternative.score,
            };
            return aux;
          });

          return ans;
        }),
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        this.getForms(this.page);
        this.modalRef.hide();
      }
    });
  }

  openScalesPage() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(["/home/scales"])
    );
    window.open(url, "_blank");
  }
}
