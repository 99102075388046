import { DatePipe } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatTableDataSource } from "@angular/material/table";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { ModalMessageComponent } from "../modals/modal-message/modal-message.component";
import { CompanyService } from "../service/company.service";
import { MatSort } from "@angular/material/sort";
import {
  startOfDay,
  endOfDay,
  subDays,
  addDays,
  endOfMonth,
  isSameDay,
  isSameMonth,
  addHours,
} from "date-fns";
import {
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTimesChangedEvent,
  CalendarView,
} from "angular-calendar";

import { ViewPeriod } from "calendar-utils";
import { RRule } from "rrule";
import { EventColor } from "calendar-utils";
import { Subject } from "rxjs";
import { CalendarService } from "../service/calendar.service";
import { ModalAddRecordComponent } from "../modals/modal-add-record/modal-add-record.component";
import { ModalAddCalendarComponent } from "./modal-add-calendar/modal-add-calendar.component";
import { ModalChoiceComponent } from "../modals/modal-choice/modal-choice.component";
import { Router } from "@angular/router";
import * as moment from "moment-timezone";
import { DoctorService } from "../service/doctor.service";
moment.tz.setDefault("UTC-3");
const colors: Record<string, EventColor> = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
  },
};
@Component({
  selector: "app-calendar",
  templateUrl: "./calendar.component.html",
  styleUrls: ["./calendar.component.css"],
})
export class CalendarComponent implements OnInit {
  view: CalendarView = CalendarView.Week;

  CalendarView = CalendarView;

  viewDate: Date = new Date();

  modalData: {
    action: string;
    event: CalendarEvent;
  };
  locale: string = "pt-BR";
  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt text-warning"></i>',
      a11yLabel: "Editar",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent("Edited", event);
      },
    },
    {
      label: '<i class="fas fa-fw fa-trash-alt text-danger"></i>',
      a11yLabel: "Deletar",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //
        this.handleEvent("Deleted", event);
      },
    },
    {
      // label:
      //   '<img src="/assets/icons/whatsapp.png" class="social-media" alt="Logo Whatsapp"/>',
      label:
        '<i style="color: #25d366; font-size: 34px; " class=" fa-lg fab fa-whatsapp text-success"></i>',
      a11yLabel: "Notificar",
      onClick: ({ event }: { event: CalendarEvent }): void => {
        //
        this.handleEvent("whatsapp", event);
      },
    },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = true;
  settings: any;

  dayStartHour: number;
  dayEndHour: number;
  dayStartMinute: number;
  dayEndMinute: number;
  excludeDays: number[];
  allEvents: any[];
  isSecretary = false;
  doctors = [];
  selected_doctors = [];
  constructor(
    private companyService: CompanyService,
    private modalService: MDBModalService,
    private datePipe: DatePipe,
    private calendarService: CalendarService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    private doctorService: DoctorService
  ) {
    const d = localStorage.getItem("doctor");
    if (d) {
      const aux = JSON.parse(d);
      if (aux.specialty === 23) this.isSecretary = true;
    }
    // console.log(d);

    if (this.isSecretary){
      this.getDoctors();
    } else {
      this.loadCalendar(this.selected_doctors);
    }

  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }


  getDoctors() {
    this.doctorService.getDoctors().subscribe(
      (data) => {
        this.doctors = data;
        this.selected_doctors = data.map((item) => item.id);
        this.loadCalendar(this.selected_doctors)
      },
      (error) => {}
    );
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent("Dropped or resized", event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    if (event.title === "Horário de almoço") {
      return;
    }
    if (action === "Edited") {
      this.openModalAdd(event);
    } else if (action === "Deleted") {
      this.deleteRecord(event);
    } else if (action === "whatsapp") {

      const id = localStorage.getItem("company");
      const aux = <any>event;
      console.log(aux)
      window.open(
        `https://api.whatsapp.com/send/?phone=+55${aux.number}&text=Olá ${
          aux.patient_name
        }. Você tem uma ${id && Number(id) === 52 ? 'vacina' : 'consulta'} marcada com o ${
          aux.doctor
        } no dia ${aux.cleanDate
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/")} às ${aux.cleanDate.split("T")[1].substr(0, 5)}.`,
        "_blank"
      );
    } else {
      if (this.isSecretary) {
        return;
      }
      const aux = <any>event;
      // this.router.navigate(["home/patient/" + ]);
      // console.log('Aqui', aux)
      this.router.navigate(["home/patient/" + aux.patient], {
        queryParams: aux.id ? { serviceId: aux.id } : {},
      });
    }
  }

  deleteRecord(event) {
    this.modalRef = this.modalService.show(ModalChoiceComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: "Confirmação",
        text: `Tem certeza que deseja deletar esse registro?`,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result == "yes") {
        this.calendarService.deleteRecord(event.id).subscribe(
          (data) => {
            this.openMessage("Sucesso!", "Registro deletado", false);
            this.events = this.events.filter((iEvent) => iEvent !== event);
          },
          (error) => {
            this.error(error, "deletar");
          }
        );
      }
    });
  }

  openModalAdd(ev = null, time = null) {
    this.modalRef = this.modalService.show(ModalAddCalendarComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: true,
      class: "modal-dialog-centered modal-dialog modal-lg modal-size",
      containerClass: "",
      animated: false,
      data: {
        title: `${!ev ? "Adicionar" : "Editar"} registro no calendário`,
        btn_text: !ev ? "Adicionar" : "Editar",
        is_new: true,
        event: ev,
        date: time ? time.date : undefined,
        hour: time ? time.hour : undefined,
      },
    });
    this.modalRef.content.action.subscribe((result: any) => {
      if (result) {
        if (!ev) {
          this.events = [...this.events, this.createEventData(result)];
        } else {
          const id = this.events.findIndex((r) => r.id === ev.id);
          const copy = this.events;
          copy[id] = this.createEventData(result);
          this.events = [...copy];
        }
        this.router
          .navigateByUrl("/", { skipLocationChange: true })
          .then(() => {
            this.router.navigate(["/home/calendar"]);
            console.log("dee ");
          });
      }
    });
  }

  createEventData(d) {
    const start = new Date(d.start_date);
    const endDate = new Date(start.getTime() + (d.procedure_time || 0) * 60000);

    const color = d.procedure
      ? { primary: d.procedure.color, secondary: d.procedure.color + "60" }
      : { ...colors.blue };

    return {
      id: d.id,
      start,
      end: endDate,
      title:
        "(" +
        d.start_date.split("T")[1].substring(0, 5) +
        ") " +
        (this.isSecretary ? `[${d.doctor.name}] ` : "") +
        "Consulta com " +
        d.patient.name +
        (d.observation ? " - " + d.observation : ""),
      doctor: d.doctor.name,
      patient_name: d.patient.name,
      color,
      actions: this.actions,
      observation: d.observation,
      number: d.patient.phone ? d.patient.phone.replace('(', '').replace(')', '').replace('-', '').split(' ').join('') : "",
      cleanDate: d.start_date,
      patient: d.patient.id,
      procedure: d.procedure,
      procedure_time: d.procedure_time,
    };
  }

  addEvent(): void {
    this.openModalAdd();
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  company_type = localStorage.getItem("companyType");
  is_loaded = true;
  ngOnInit(): void {
  
    
  }

  loadCalendar(doctors): void {
    this.is_loaded = false;
    this.calendarService.getAllCalendar(doctors).subscribe((data) => {
      this.is_loaded = true;

      const ev = [];
      for (const d of data.calendars) {
        ev.push(this.createEventData(d));
      }

      this.allEvents = ev;
      this.settings = data.settings;

      this.dayStartHour = Math.floor(data.settings.start_hour / 60);
      this.dayStartMinute = data.settings.start_hour % 60;

      this.dayEndHour = Math.floor(data.settings.end_hour / 60);
      this.dayEndMinute = data.settings.end_hour % 60;

      const ex = [];
      const has = {};
      for (const d of data.settings.daysOfWeek) {
        has[d] = true;
      }

      for (const d in [0, 1, 2, 3, 4, 5, 6]) {
        if (!has[d]) {
          ex.push(Number(d));
        }
      }
      this.excludeDays = ex;
    });
  }

  viewPeriod: ViewPeriod;

  getLunchEvent(viewRender) {
    if (
      !this.settings ||
      !this.settings.lunch_start ||
      !this.settings.lunch_end
    ) {
      this.events = this.allEvents;
      return;
    }

    if (
      !this.viewPeriod ||
      !moment(this.viewPeriod.start).isSame(viewRender.period.start) ||
      !moment(this.viewPeriod.end).isSame(viewRender.period.end)
    ) {
      this.viewPeriod = viewRender.period;

      this.events = [...this.allEvents];
      const recurringEvents = [
        {
          title: "Horário de almoço",
          color: colors.red,
          rrule: {
            freq: RRule.DAILY,
            byhour: [Math.floor(this.settings.lunch_start / 60)],
            byminute: [this.settings.lunch_start % 60],
          },
        },
      ];
      recurringEvents.forEach((event) => {
        const rule: RRule = new RRule({
          ...event.rrule,
          dtstart: moment(viewRender.period.start).startOf("day").toDate(),
          until: moment(viewRender.period.end).endOf("day").toDate(),
        });
        const { title, color } = event;
        rule.all().forEach((date) => {
          this.events.push({
            title,
            color,
            start: moment(
              date.setHours(
                Math.floor(this.settings.lunch_start / 60),
                this.settings.lunch_start % 60
              )
            ).toDate(),
            end: moment(
              date.setHours(
                Math.floor(this.settings.lunch_end / 60),
                this.settings.lunch_end % 60
              )
            ).toDate(),
          });
        });
      });
      this.cdr.detectChanges();
    }
  }

  modalRef: MDBModalRef;
  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  twoPlaces(d) {
    return d < 10 ? "0" + d : d;
  }

  openDayWithClick(d) {
    const date =
      this.twoPlaces(d.getDate()) +
      "/" +
      this.twoPlaces(d.getMonth() + 1) +
      "/" +
      d.getFullYear();
    this.openModalAdd(null, { date: date, hour: undefined });
  }

  openHourWithClick(d) {
    const date =
      this.twoPlaces(d.getDate()) +
      "/" +
      this.twoPlaces(d.getMonth() + 1) +
      "/" +
      d.getFullYear();
    const hour =
      this.twoPlaces(d.getHours()) + ":" + this.twoPlaces(d.getMinutes());
    this.openModalAdd(null, { date: date, hour: hour });
  }

  updateDoctors(e) {
    if (!e) {
      // console.log(this.selected_doctors)
      this.loadCalendar(this.selected_doctors)
    }
  }
}
