import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "modal-doctor-select-company",
  templateUrl: "./modal-doctor-select-company.component.html",
  styleUrls: ["./modal-doctor-select-company.component.css"],
})
export class ModalDoctorSelectCompanyComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private router: Router,
    private userService: UserService,
    
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  doctor_clone;

  companyId = null;
  validator = new ValidatorUtils();
  loading = false;

  companies = [];

  ngOnInit() {
    this.companyId = parseInt(localStorage.getItem("company"));
    this.getCompanies();
  }

  getCompanies() {
    this.loading = true;
    this.doctorService.getCompanies().subscribe((data) => {
      this.companies = [...data];

      this.loading = false;
    });
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }

  changeCompany(company) {
    
    this.doctorService.getCompanyById(company.id).subscribe((data) => {

      localStorage.setItem("company", company.id);
      localStorage.setItem("admin", data.isCompanyAdmin);
      let _aux = data.company.settings;
      _aux['company'] = company;
      localStorage.setItem('company_settings', JSON.stringify(_aux));
      localStorage.setItem('doctor', JSON.stringify(data.doctor));
      localStorage.setItem('companyType', _aux.company.company_type);
      

      this.router.navigate([ _aux.company.company_type === 'CLINIC' ? "home/calendar" : "home/dashboard"]).then(() => {
        window.location.reload();
      });
      this.modalRef.hide();
    });
  }
}
