import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MDBModalRef, MDBModalService } from "angular-bootstrap-md";
import { Subject } from "rxjs";
import { ModalMessageComponent } from "src/app/modals/modal-message/modal-message.component";
import { DoctorService } from "../../service/doctor.service";
import { ValidatorUtils } from "src/app/utils/validator.utils";
import { Md5 } from "md5-typescript";
import { CompanyService } from "src/app/service/company.service";
import { Router } from "@angular/router";
import { UserService } from "src/app/service/user.service";

@Component({
  selector: "modal-doctor-detail",
  templateUrl: "./modal-doctor-detail.component.html",
  styleUrls: ["./modal-doctor-detail.component.css"],
})
export class ModalDoctorDetailComponent {
  constructor(
    public modalRef: MDBModalRef,
    private modalService: MDBModalService,
    private doctorService: DoctorService,
    private companyService: CompanyService,
    private datePipe: DatePipe,
    private router: Router,
    private userService: UserService
  ) {}

  action: Subject<any> = new Subject();
  modalRef2: MDBModalRef;

  title: string = "";
  btn_text: string;
  is_new: boolean = true;

  doctor_clone;
  company = [];
  company_selected = null;
  patient = [];
  patient_selected = [];
  allPatient = []
  doctor = {
    name: null,
    email: null,
    birthday: null,
    phone: null,
    company: null,
    cpf: null,
    crm: null,
    rqe: null,
    gender: null,
    association: null,
    specialty: null,
    city: null,
    state: null,
    street: null,
    neighborhood: null,
    number: null,
    complement: null,
    password: null,
    active: true,
    patient: [],
  };

  confirm_password: string;

  validator = new ValidatorUtils();

  associations = [];
  specialties = [];

  ngOnInit() {
    if (this.doctor_clone != null && !this.is_new) {
      this.company_selected = parseInt(localStorage.getItem("company"), 10);
      for (var key in this.doctor) {
        if (key == "cpf") this.doctor[key] = this.doctor_clone.user[key];
        else if (key == "birthday" && this.doctor_clone[key] != null) {
          this.doctor[key] = `${this.doctor_clone[key].substring(
            8,
            10
          )}/${this.doctor_clone[key].substring(5, 7)}/${this.doctor_clone[
            key
          ].substring(0, 4)}`;
        } else if (key != "password") this.doctor[key] = this.doctor_clone[key];
      }
    }

    Promise.all([
      this.companyService.getCompanys().subscribe((data) => {
        const company = parseInt(localStorage.getItem("company"));
        this.company = data.filter((item) => item.id === company);
      }),
      this.getPatients(),
      this.getPatientDoctor(),
    ]);
  }

  checkDoctor() {
    if (this.validator.isEmptyOrNull(this.doctor.name)) {
      this.openMessage("Por favor,", "insira o nome do médico.", false);
      return false;
    } /*else if (this.validator.isEmptyOrNull(this.doctor.email)) {
      this.openMessage("Por favor,", "insira o email do médico.", false);
      return false;
    } */ else if (this.validator.isEmptyOrNull(this.doctor.cpf)) {
      this.openMessage("Por favor,", "insira o CPF do médico.", false);
      return false;
    } else if (
      this.is_new &&
      this.validator.isEmptyOrNull(this.doctor.password)
    ) {
      this.openMessage("Por favor,", "insira a senha do paciente.", false);
      return false;
    } else if (
      this.is_new &&
      this.validator.isEmptyOrNull(this.confirm_password)
    ) {
      this.openMessage(
        "Por favor,",
        "confirme a senha do profissional.",
        false
      );
      return false;
    } else if (this.is_new && this.doctor.password != this.confirm_password) {
      this.openMessage("Erro,", "as senhas são diferentes.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.doctor.birthday)) {
      this.openMessage(
        "Por favor,",
        "insira a data de nascimento do médico.",
        false
      );
      return false;
    } else if (this.validator.isEmptyOrNull(this.doctor.gender)) {
      this.openMessage("Por favor,", "selecione o gênero do médico.", false);
      return false;
    } else if (this.validator.isEmptyOrNull(this.doctor.state)) {
      this.openMessage("Por favor,", "informe o estado do médico.", false);
      return false;
    } else if (this.is_new && this.validator.isEmptyOrNull(this.company_selected)) {
      this.openMessage("Por favor,", "informe a empresa.", false);
      return false;
    } else {
      var day = this.doctor.birthday.substring(0, 2);
      var month = this.doctor.birthday.substring(3, 5);
      var year = this.doctor.birthday.substring(6, 10);

      var date = new Date(year, month - 1, day);
      var time = new Date().getTime() - date.getTime();
      var time_passed = time / 1000 / 60;

      if (day > 31 || day < 1 || month > 12 || month < 1 || year < 1900) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else if (time_passed < 0) {
        this.openMessage(
          "Por favor,",
          "insira uma data de nascimento válida",
          false
        );
        return false;
      } else {
        if (this.is_new) this.createDoctor(`${year}-${month}-${day}`);
        else this.editDoctor(`${year}-${month}-${day}`);
      }
    }
  }

  createDoctor(birthday) {
    this.openMessage("Por favor, aguarde", "", true);
    var passwordMD5 = Md5.init(this.doctor.password).toUpperCase();
    if (this.company_selected) {
      this.doctor.company = this.company_selected;
    }
    if (this.patient_selected.length > 0) {
      this.doctor.patient = this.patient_selected;
    }

    const body = this.doctor;
    if (!body.email || !body.email.length) {
      delete body.email;
    }
    this.doctorService.newDoctor(body, passwordMD5, birthday).subscribe(
      (data) => {
        this.modalRef2.hide();
        this.openMessage("Criado!", "Profissional criado com sucesso.", false);
        this.action.next("update");
        this.modalRef.hide();
      },
      (error) => {
        this.modalRef2.hide();
        this.error(error, "criar");
      }
    );
  }

  editDoctor(birthday) {
    this.openMessage("Por favor, aguarde", "", true);
    if (this.company_selected) {
      this.doctor.company = this.company_selected;
    }
    if (this.patient_selected.length > 0) {
      this.doctor.patient = this.patient_selected;
    }
    const body = this.doctor;
    if (!body.email || !body.email.length) {
      delete body.email;
    }
    this.doctorService
      .editDoctor(body, this.doctor_clone.id, birthday)
      .subscribe(
        (data) => {
          this.modalRef2.hide();
          this.openMessage(
            "Salvo!",
            "Profissional editado com sucesso.",
            false
          );
          this.action.next("update");
          this.modalRef.hide();
        },
        (error) => {
          this.modalRef2.hide();
          this.error(error, "editar");
        }
      );
  }

  getPatients() {
    this.userService.getPatientsPagination(1).subscribe(
      (data) => {
        this.patient = data;
        this.allPatient = data;
        if (this.is_new) this.patient_selected = data.map((item) => item.id);
      },
      (error) => {
        localStorage.removeItem("token");
        this.router.navigate(["/login"]);
      }
    );
  }

  getPatientDoctor() {
    if (this.is_new) return;
    this.doctorService.getPatientsByDoctor(this.doctor_clone.id).subscribe(
      (data) => {
        this.patient_selected = data.map((item) => item.id);
      },
      (error) => {
        localStorage.removeItem("token");
        this.router.navigate(["/login"]);
      }
    );
  }

  accentFold(inStr) {
    return inStr.replace(
      /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|([ñ])|([òóôõöø])|([ß])|([ùúûü])|([ÿ])|([æ])/g,
      function (str, a, c, e, i, n, o, s, u, y, ae) {
        if (a) return "a";
        if (c) return "c";
        if (e) return "e";
        if (i) return "i";
        if (n) return "n";
        if (o) return "o";
        if (s) return "s";
        if (u) return "u";
        if (y) return "y";
        if (ae) return "ae";
      }
    );
  }

  selectSearchPatient(value: string) {
    this.patient = [];
    let filter = this.accentFold(value.toLowerCase());
    for (let i = 0; i < this.allPatient.length; i++) {
      let option = this.allPatient[i];
      if (this.accentFold(option.name.toLowerCase()).indexOf(filter) >= 0) {
        this.patient.push(option);
      }
    }
  }

  error(error, string) {
    var msg = "";
    if (
      error.error != null &&
      error.error.error != null &&
      error.error.error.length < 50
    )
      msg = error.error.error;
    else msg = "Por favor verifique as informações inseridas";
    this.openMessage(`Erro, não foi possível ${string} o médico.`, msg, false);
  }

  openMessage(title, text, bgclick: boolean) {
    this.modalRef2 = this.modalService.show(ModalMessageComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: true,
      ignoreBackdropClick: bgclick,
      class: "modal-dialog-centered modal-dialog",
      containerClass: "",
      animated: false,
      data: {
        title: title,
        text: text,
      },
    });
  }
}
